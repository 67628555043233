body {
  /* margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  background: #e2f7f1;
  background-size: cover;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  margin: 0;
  padding: 0;
  color: #3c4858;
}

.topNav {
  background: linear-gradient(0.25turn, #041838, #026761, #00d99d, #ccefd8);
}
.newButton {
  padding-left: 0px !important;
}
.navGutter {
  width: 150px;
  min-width: 150px;
}
.navContent {
  width: 100%;
}

.layoutGutter {
  width: 3% !important;
}
.layoutContent {
  width: 94% !important;
}

.navbar {
  background: transparent !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

@media screen and (max-width: 35em) {
  .navbar-collapse {
    background: linear-gradient(0.5turn, #e2f7f1, #bed3cd);
  }
}

.my-modal {
  display: grid;
  grid-template-rows: minmax(5px, max-content) auto minmax(5px, max-content);
  align-items: center;
  min-width: 340px;
}

.new-modal {
  min-width: 340px;
}

h3,
h4 {
  color: #00d89d;
  font-weight: bold;
}

.normalText {
  color: #7c7e80;
}

.makeBold {
  font-weight: bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.titleText {
  color: #00d89d !important;
}

.alignleft {
  float: left;
  width: 50%;
  text-align: left;
}

.alignright {
  float: right;
  width: 50%;
  text-align: right;
}

.alignPagination {
  float: right;
  text-align: right;
}

.standardButton {
  border-width: 0;
  align-items: "center";
  padding-left: 20px;
  padding-right: 20px;
  justify-content: "center";
  height: 90%;
  background-color: #00d89c;
  border-radius: 50px;
}
.standardButton:disabled {
  background-color: #99efd9;
  pointer-events: none;
}

.standardButton:hover {
  -webkit-box-shadow: 0px 0px 100px 0px rgba(0, 216, 156, 1);
  -moz-box-shadow: 0px 0px 100px 0px rgba(0, 216, 156, 1);
  box-shadow: 0px 0px 10px 0px rgba(0, 216, 156, 1);
}

.filterList {
  position: relative;
}

.filterLink {
  width: 100%;
  cursor: pointer !important;
  padding-left: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}

.filterLink:hover {
  background-color: #cfe6e0;
}

.firstFilter {
  margin-left: 80px;
}

.filterSelected {
  background-color: #99eed7;
}

@media screen and (max-width: 50em) {
  .filterList {
    display: inline-flex;
  }

  .filterLink {
    display: inline-flex;
    min-width: 25% !important;
    width: 25% !important;
    cursor: pointer !important;
    padding-left: 10px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 0px;
  }

  .filterLink:hover {
    background: #99eed7;
  }

  .filterSelected {
    background: #e2f7f1 !important;
  }

  .navTitle {
    display: none;
  }
}

.boldBlack {
  color: #000000 !important;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn-xxl {
  padding: 1rem 1.5rem;
  font-size: 1.5rem;
}

.textBox {
  width: 80% !important;
  min-width: 200px !important;
  height: 35px;
  padding-left: 10px;
  margin: 0 auto;
  border: none;
  border: solid 1px #ccc;
  border-radius: 20px;
}

.textBox:focus {
  outline: none;
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
