thead > tr > th {
  font-style: normal;
  cursor: auto;
  font-family: "Gotham", Arial, sans-serif !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border-collapse: separate;
  border-spacing: 0;
  empty-cells: show;
  margin: 0;
  display: table-cell;
  line-height: 1.125rem;
  background-repeat: repeat;
  background-image: none;
  background-position: 50% 50%;

  background: #e2f7f1;
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: bold !important;
  color: #000000;
  overflow: hidden;
  border-style: solid !important;
  border-width: 0px 0px 2px 0px !important;
  padding: 0.5em 0.6em 0.4em 0.6em !important;
  white-space: nowrap;
  text-overflow: ellipsis !important;
  text-align: left;
}

tbody > tr > td {
  vertical-align: top !important;
  border-color: #cdcdcd !important;
  border-style: solid !important;
  border-width: 1px 0px 0px 0px !important;
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis !important;
  padding-left: 0.5em !important;
}

tbody > tr > td:first-child {
  border-left-color: transparent !important;
  border-left-width: 3px !important;
}

tbody > tr.active > td:first-child {
  border-left-color: #00d89d !important;
  border-left-width: 3px !important;
}

/* .altRow {
  background: #f9f9f9;
  background-color: #f9f9f9;
} */

.newRow {
  font-weight: bold;
  color: black !important;
}

.selected {
  background-color: #e2e7ef !important;
  border-color: #e2e7ef !important;
}

.sortable {
  cursor: pointer !important;
}

.asc:after {
  padding: 5px;
  content: "▲";
  /* float: right; */
}

.desc:after {
  padding: 5px;
  content: "▼";
  /* float: right; */
}

.collapsed:after {
  content: "▶";

  font-size: 10pt;
  /* float: right; */
}

.expanded:after {
  content: "▼";
  font-size: 10pt;
  /* float: right; */
}
.collapsed:hover:after {
  color: #00d89c !important;
}

.expanded:hover:after {
  color: #00d89c !important;
}

.page-link {
  color: #7c7e80 !important;
  background: #e2f7f1 !important;
}

li.page-item.active > a {
  border-color: #7c7e80 !important;
  background: white !important;
  color: #00d89d !important;
}
