.selection-root {
  /* border: 1px solid gray; */
  /* border-radius: 4px; */
  position: relative;
}

/* .overlayMLS {
  background: rgba(0, 0, 0, 0.1);
  height: 100vh;
  left: 0;
  margin: 0 auto;
  overflow-y: hidden;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.25s ease;
  width: 100%;
  z-index: 2;
} */

.selection-header {
  cursor: pointer;
  overflow: hidden;
  padding-right: 28px;
  padding: 8px 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
  background-color: #ffffff;
}

.selection-entries {
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid papayawhip;
  margin-top: 8px;
  max-width: 600px;
  overflow-x: auto;
  padding: 8px;
  /* position: absolute; */
  z-index: 3;
}

.entry-level {
  list-style-type: none;
  margin: 0;
  max-height: 300px;
  overflow: auto;
  padding: 0;
  width: 200px;
}

.entry-item {
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  padding: 8px 16px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.entry-item:hover,
.entry-item__selected {
  background-color: #99efd9;
}

.entry-item__nestable {
  padding-right: 24px;
}

.entry-item__nestable::after {
  content: "〉";
  position: absolute;
  right: 8px;
}

.select-wrapper {
  position: relative;
}

.select-wrapper::after {
  color: #2b2c2e;
  content: "\2304";
  font-family: "Roboto";
  font-size: 1.6em;
  font-weight: 400;
  pointer-events: none;
  position: absolute;
  right: 8px;
  top: -8px;
}

.hidden {
  display: none;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-align-start {
  align-items: start;
}
