.statusBarStatic {
  width: 100%;
  height: 15px;
  background: linear-gradient(0.25turn, black, #00d89c, black);
  background-size: 100% 50%;
}

.statusBarUpload {
  width: 100%;
  height: 15px;
  color: #fff;
  background: linear-gradient(0.25turn, black, #00d89c, black, #00d89c, black);
  background-size: 200% 100%;
  -webkit-animation: Gradient 12s ease infinite;
  -moz-animation: Gradient 12s ease infinite;
  animation: Gradient 12s ease infinite;
}

.statusBarPreview {
  width: 100%;
  height: 15px;
  color: #fff;
  background: linear-gradient(0.25turn, black, #026bd4, black, #026bd4, black);
  background-size: 200% 100%;
  -webkit-animation: Gradient 12s ease infinite;
  -moz-animation: Gradient 12s ease infinite;
  animation: Gradient 12s ease infinite;
}

.statusBarPaused {
  -webkit-animation-play-state: paused; /* Safari 4.0 - 8.0 */
  animation-play-state: paused;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
