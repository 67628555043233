thead > tr > th.mgdUserCheck,
tbody > tr > td.mgdUserCheck,
tfoot > tr > td.mgdUserCheck {
  min-width: 50px;
  max-width: 50px;
  word-wrap: break-word !important;
}

thead > tr > th.mgdUserName,
tbody > tr > td.mgdUserName,
tfoot > tr > td.mgdUserName {
  min-width: 175px;
  max-width: 175px;
  word-wrap: break-word !important;
}

thead > tr > th.mgdUserEmail,
tbody > tr > td.mgdUserEmail,
tfoot > tr > td.mgdUserEmail {
  min-width: 175px;
  max-width: 175px;
}

thead > tr > th.mgdUserLastLogin,
tbody > tr > td.mgdUserLastLogin,
tfoot > tr > td.mgdUserLastLogin {
  min-width: 100px;
  max-width: 100px;
  word-wrap: break-word !important;
  white-space: normal !important;
}



