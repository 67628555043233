/* @media only screen and (max-width: 50em) {
  body {
    font-size: 0.8em !important;
  }
} */

.app {
  display: grid;
  grid-gap: 1px 1px;

  grid-template-columns: 190px auto;
  grid-template-rows: 80px 50px 1fr;
  font-family: Helvetica, Arial, sans-serif;
}
.app > * {
  padding: 5px;
}
.header {
  grid-column: 1 / -1;
}
.topright {
  grid-row: 2;
  grid-column: 2;
}
.topleft {
  grid-row: 2;
  grid-column: 1;
}

.sidebar {
  padding-bottom: 0px !important;
  grid-row: 3;
  grid-column: 1/-1;
  background: linear-gradient(0.5turn, #e2f7f1, #bed3cd);
}
.content {
  grid-row: 4/-1;
  grid-column: 1/-1;
}
/* Now let's apply grid for wider viewports. */
@media screen and (min-width: 50em) {
  .app > * {
    margin-bottom: 0px;
  }
  /* Define the grid */
  .app {
    display: grid;
    grid-gap: 1px 1px;
    grid-template-columns: 190px auto;
    grid-template-rows: 80px 50px 1fr;
    height: 98vh;
  }
  /* Place items on the grid */
  .header {
    padding-left: 15px;
    padding-right: 15px;
    grid-column: 1 / -1;
  }
  .topleft {
    padding-left: 15px;
    grid-row: 2;
    grid-column: 1;
  }
  .topright {
    padding-right: 15px;
    grid-row: 2;
    grid-column: 2;
  }
  .sidebar {
    padding-left: 8px;
    grid-row: 3;
    grid-column: 1;
    background: #e2f7f1;
  }
  .content {
    padding-right: 15px;
    grid-row: 3;
    grid-column: 2/-1;
  }
}

@media (max-width: 767px) {
  .app {
    display: grid;
    grid-gap: 1px 1px;

    grid-template-columns: 190px auto;
    grid-template-rows: 80px 70px 1fr;
    font-family: Helvetica, Arial, sans-serif;
  }

  .messageTranscriptsToggle {
    display: none;
  }

  .filterLink {
    border-style: solid;
    border-width: thin;
    height: 25px;
  }

  .workingLoading {
    width: 15px !important;
    height: 15px !important;
    margin-top: 3px;
    margin-right: 2px;
  }
}
