.cdTableHeader {
  border-radius: 0.25em;
  border: 1px solid gray;
  padding: 0.5em;
}

.exTableHeader {
  background-color: #004c97 !important;
  padding: 0.22em 0.2em 0.28em;
  border-width: 0 0 1px;
  display: flex;
  justify-content: space-between;
}
.exFilter {
  color: white !important;
  vertical-align: middle;
}
.cdPadding {
  padding: 1em;
}

.cdAccountDisplay {
  padding: 1em;
}

.cdFloatRight {
  float: right;
}

.cdFloatLeft {
  float: left;
}

.cdFloatRight::after {
  content: "";
  clear: both;
  display: table;
}

.cdExcludedLabel {
  font-weight: normal;
  padding-right: 10px;
}

.cdCellButton {
  padding: 1px 4px;
}

.cdMonospace {
  font-family: "Lucida Console", Monaco, monospace;
}

.cdTextRight {
  text-align: right;
}

.cdExcluded {
  background: #dbdbdb;
}

.cdReadOnly {
  background: #dbdbdb;
  font-style: italic;
}

#errorModalTitle {
  color: red;
}

tbody > tr > td.cdTableCell {
  padding: 1px;
  padding-top: 15px;
}

tbody > tr > td.cdSubRowPadding {
  padding-left: 30px;
}

tbody.cdBodyContainer {
  max-height: calc(100vh - 250px);
  min-height: calc(100vh - 250px);
  overflow-y: scroll;
  display: block;
}

@media screen and (max-width: 50em) {
  tbody.cdBodyContainer {
    max-height: calc(100vh - 300px);
    min-height: calc(100vh - 300px);
    overflow-y: scroll;
    display: block;
  }
}
thead.cdBodyContainer,
tfoot.cdBodyContainer,
tbody.cdBodyContainer tr {
  display: table;
  width: 100%;
  margin: 0 !important;
}
thead > tr > th.ffAction,
tbody > tr > td.ffAction,
tfoot > tr > td.ffAction {
  min-width: 28px;
  max-width: 28px;
  padding-left: 0px !important;
}

thead > tr > th.img-frame-wrapper {
  width: 180px;
  min-width: 180px;
  max-width: 180px;
}

tbody > tr > td.img-frame-wrapper,
tfoot > tr > td.img-frame-wrapper {
  width: 180px;
  min-width: 180px;
  max-width: 180px;
  padding-bottom: 15px !important;
}

thead > tr > th.ffTargets,
tbody > tr > td.ffTargets,
tfoot > tr > td.ffTargets {
  min-width: 150px;
  max-width: 150px;
}

thead > tr > th.ffSubject,
tbody > tr > td.ffSubject,
tfoot > tr > td.ffSubject {
  min-width: 300px;
  max-width: 300px;
}

thead > tr > th.ffSummary,
tbody > tr > td.ffSummary,
tfoot > tr > td.ffSummary {
  min-width: 300px;
  max-width: 300px;
  word-wrap: break-word !important;
}

thead > tr > th.ffMessage,
tbody > tr > td.ffMessage,
tfoot > tr > td.ffMessage {
  min-width: 250px;
  max-width: 250px;
  word-wrap: break-word !important;
  white-space: normal !important;
  padding-right: 10px !important;
}

thead > tr > th.ffDate,
tbody > tr > td.ffDate,
tfoot > tr > td.ffDate {
  min-width: 100px;
  max-width: 100px;
  word-wrap: break-word !important;
  white-space: normal !important;
}

thead > tr > th.scrollColumn,
tbody > tr > td.scrollColumn,
tfoot > tr > td.scrollColumn {
  width: 17px;
  min-width: 17px;
  max-width: 17px;
  padding: 0px;
}

@media screen and (max-width: 50em) {
  thead > tr > th.ffSubject,
  tbody > tr > td.ffSubject,
  tfoot > tr > td.ffSubject {
    display: none;
  }

  thead > tr > th.ffSummary,
  tbody > tr > td.ffSummary,
  tfoot > tr > td.ffSummary {
    display: none;
  }

  thead > tr > th.img-frame-wrapper {
    width: 150px;
    min-width: 150px;
    max-width: 150px;
  }

  tbody > tr > td.img-frame-wrapper,
  tfoot > tr > td.img-frame-wrapper {
    width: 150px;
    min-width: 150px;
    max-width: 150px;
    padding-bottom: 15px !important;
  }

  thead > tr > th.ffTargets,
  tbody > tr > td.ffTargets,
  tfoot > tr > td.ffTargets {
    min-width: 120px;
    max-width: 120px;
  }
}

@media screen and (max-width: 30em) {
  thead > tr > th.ffTargets,
  tbody > tr > td.ffTargets,
  tfoot > tr > td.ffTargets {
    font-size: smaller;
  }

  thead > tr > th.ffDate,
  tbody > tr > td.ffDate,
  tfoot > tr > td.ffDate {
    font-size: smaller;
    min-width: 70px;
    max-width: 70px;
  }

  thead > tr > th.img-frame-wrapper,
  tbody > tr > td.img-frame-wrapper,
  tfoot > tr > td.img-frame-wrapper {
    font-size: smaller;
  }
}
@media screen and (max-width: 70em) {
  thead > tr > th.ffMessage,
  tbody > tr > td.ffMessage,
  tfoot > tr > td.ffMessage {
    display: none;
  }
}

/* Bootstrap override */
table.table {
  margin-bottom: 5px;
}
/* 
thead > tr > th.cdAddModalType {
  padding: 5px 1px;
}

thead > tr > th.cdAddModalAdd,
tbody > tr > td.cdAddModalAdd,
tfoot > tr > td.cdAddModalAdd {
  width: 20px;
}

thead > tr > th.cdAddModalType,
tbody > tr > td.cdAddModalType,
tfoot > tr > td.cdAddModalType {
  width: 35px;
}

thead > tr > th.cdAddModalDescription,
tbody > tr > td.cdAddModalDescription,
tfoot > tr > td.cdAddModalDescription {
  width: 150px;
  max-width: 150px;
  overflow-wrap: break-word;
}

thead > tr > th.cdAddModalAmount,
tbody > tr > td.cdAddModalAmount,
tfoot > tr > td.cdAddModalAmount {
  min-width: 100px;
  max-width: 100px;
}

thead > tr > th.cdAddModalReason,
tfoot > tr > td.cdAddModalReason {
  min-width: 272px;
  max-width: 272px;
}

tbody > tr > td.cdAddModalReason {
  min-width: 255px;
  max-width: 255px;
}

thead > tr > th.cdExcludeModalReason,
tfoot > tr > td.cdExcludeModalReason {
  min-width: 127px;
  max-width: 127px;
}

tbody > tr > td.cdExcludeModalReason {
  min-width: 120px;
  max-width: 120px;
} */

.custom-context {
  border: solid 1px #ccc;
  display: inline-block;
  margin: 5px;
  background: #fff;
  color: #000;
  font-family: sans-serif;
  cursor: pointer;
  font-size: 12px;
}
.custom-context-item {
  border-bottom: dotted 1px #ccc;
  padding: 5px 25px;
}
.custom-context-item-last {
  padding: 5px 25px;
}
