thead > tr > th.impFileName,
tbody > tr > td.impFileName,
tfoot > tr > td.impFileName {
  min-width: 175px;
  max-width: 175px;
  word-wrap: break-word !important;
}

thead > tr > th.impFileSize,
tbody > tr > td.impFileSize,
tfoot > tr > td.impFileSize {
  min-width: 50px;
  max-width: 50px;
}

thead > tr > th.impFileDate,
tbody > tr > td.impFileDate,
tfoot > tr > td.impFileDate {
  min-width: 100px;
  max-width: 100px;
  word-wrap: break-word !important;
  white-space: normal !important;
}

thead > tr > th.impFileSubject,
tbody > tr > td.impFileSubject,
tfoot > tr > td.impFileSubject {
  min-width: 180px;
  max-width: 180px;
  word-wrap: break-word !important;
  white-space: normal !important;
  padding-right: 10px !important;
}

thead > tr > th.impFileStatus,
tbody > tr > td.impFileStatus,
tfoot > tr > td.impFileStatus {
  min-width: 50px;
  max-width: 50px;
}

thead > tr > th.scrollColumn,
tbody > tr > td.scrollColumn,
tfoot > tr > td.scrollColumn {
  width: 17px;
  min-width: 17px;
  max-width: 17px;
  padding: 0px;
}

.importUploadProgress {
  padding: 10px;
  margin: auto;
  overflow: hidden;
  background-color: #e2f7f1 !important;
  color: black !important;
  border-radius: 5px;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.5);
}

@media (max-width: 767px) {
  .impFileSize {
    display: none;
  }
  .impFileDate {
    display: none;
  }

  thead > tr > th.impFileName,
  tbody > tr > td.impFileName,
  tfoot > tr > td.impFileName {
    max-width: 75px;
    min-width: 75px;
    word-wrap: break-word !important;
  }
}
