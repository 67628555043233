.card-hover:hover {
  transform: scale(1.05);
  transition: transform .2s;
}

.card-img-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.card-img-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 48px;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  transform: translate(-50%, -50%);
}

