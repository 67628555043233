@import "~material-design-icons/iconfont/material-icons.css";

* {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.App {
  text-align: center;
}

.navLink {
  color: black !important;
  font-size: 12px;
}

.navLink:hover {
  text-decoration: underline !important;
}

.data-area-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.demo-section {
  padding-bottom: 2rem;
  border-bottom: 2px solid;
}

.demo-section:last-child {
  border: none;
}
.center-me {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.uploadProgress {
  width: 200px;
  padding: 10px;
  margin: auto;
  overflow: hidden;
  background-color: #e2f7f1 !important;
  color: black !important;
  border-radius: 5px;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.5);
}

.img-grid {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
}

@media screen and (max-width: 50em) {
  .img-grid {
    display: block;
  }
}

.img-wrapper {
  width: 180px !important;
  margin: auto;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.5);
}

.img-wrapper-noshadow {
  width: 140px !important;
  height: 150px !important;
  margin: auto;
  overflow: hidden;
}

.clickable {
  cursor: pointer !important;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.subject-line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal !important;
  text-align: center;
  margin-top: 5px;
}

.video-player {
  grid-area: video / video / video / video;
  /* width: 796px;
  min-height: 498px; */
  min-width: 276px;
  max-width: 2000px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #000;
}

.video-player .overlay {
  position: absolute;
  color: #00d89d;
  top: 50%;
  left: 50%;
  margin: 0;
  transform: translate(-50%, -50%);
  font-size: 124pt;
  font-weight: bold;
  line-height: 17px;
}

.video-placeholder-frame {
  width: 100%;
  position: relative;
  padding-bottom: 62.5%; /* ratio 16/10 */
}
.video-placeholder {
  border: none;
  position: absolute;
  width: 100%;
  /* height: 100%; */
  background: black;
}
.search-panel {
  float: right;
  width: 50%;
  min-width: 200px;
  padding: 2px 2px 2px 5px;
  border: 1px solid #eae6eb;
  background-color: white;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}
.search-btn {
  background: transparent;
  border-color: transparent;
  width: 1.75rem;
  height: 1.75rem;
  background: #99efd9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--clr-red-special);
  cursor: pointer;
  margin-left: 1rem;
  align-self: center;
  min-width: 1.75rem;
}
.search-btn:hover {
  -webkit-box-shadow: 0px 0px 100px 0px rgba(0, 216, 156, 1);
  -moz-box-shadow: 0px 0px 100px 0px rgba(0, 216, 156, 1);
  box-shadow: 0px 0px 10px 0px rgba(0, 216, 156, 1);
}

.search-panel header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-panel header input {
  width: 100% !important;
  outline: none !important;
  border: 0 none !important;
  display: inline-flex !important;
  line-height: 1;
  vertical-align: baseline !important;
  padding: 0.4em 0.4em !important;
  color: black;
  background-color: white;
  font-size: 1rem;
}

.summary-panel {
  padding: 5px;
  margin-top: 5px;
  border: 1px solid #eae6eb;
  background-color: white;

  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;

  white-space: pre-line;
}

.ado-panel {
  padding: 5px;
}

.info-panel {
  padding: 5px;
  border: 1px solid #eae6eb;
  background-color: white;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.info-panel header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info-title {
  padding-left: 0.5rem;
  cursor: pointer;
}

.info-btn {
  background: transparent;
  border-color: transparent;
  width: 2rem;
  height: 2rem;
  background: #99efd9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--clr-red-special);
  cursor: pointer;
  margin-left: 1rem;
  align-self: center;
  min-width: 2rem;
}
.info-btn.ip-up {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-8 -8 32 32' fill='%230c63e4'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  transform: rotate(-180deg);
}
.info-btn.ip-down {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-8 -8 32 32' fill='%230c63e4'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
}

.transcript {
  max-height: 200px;
  min-height: 80px;
  background-color: white;
  border-width: 1px;
  border-color: #cfe6e0;
  border-style: solid;
  overflow-y: scroll;
  padding: 10px;
  margin: 0.5rem;
}
.countdown-1 {
  background-image: url("../assets/countdown_1.png");
  z-index: 100;
}
.countdown-2 {
  background-image: url("../assets/countdown_2.png");
  z-index: 100;
}
.countdown-3 {
  background-image: url("../assets/countdown_3.png");
  z-index: 100;
}

.message-box {
  border: solid 1px #ccc;
}
.working {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -60px 0px 0px -60px;
  transform: translate(-50%, -50%);
  animation: spin 2s infinite linear;
}
.workingSide {
  position: absolute;
  top: 190px;
  left: 20px;
  transform: translate(-50%, -50%);

  animation: spinRev 2s infinite linear;
}

.workingLoading {
  animation: spin 2s infinite linear;
  width: 15px;
}

.workingLoadingDone {
  display: none;
}

.bucket {
  display: inline-block;
  width: 125px;
}

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}

.workingRev {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -60px 0px 0px -60px;
  transform: translate(-50%, -50%);
  animation: spinRev 2s infinite linear;
}
@keyframes spinRev {
  from {
    transform: scale(1) rotate(360deg);
  }
  to {
    transform: scale(1) rotate(0deg);
  }
}

.alignLeft {
  flex: 1;
  text-align: left;
}

.alignRight {
  flex: 1;
  text-align: right;
  white-space: nowrap;
}

.alignMiddle {
  margin: 0;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
}

.btn-secondary.active {
  background-color: darkslategray !important;
  border-color: black !important;
}

/* AI active Indicator (logo in top-right corner visible when recording)
--------------------------------------------------------------------------------
*/

.ai-indicator {
  width: 0.7em;
  height: 0.7em;
  line-height: 1.1em;
  right: 2.75ex;
  top: 9.75ex;
  transform: rotateX(0) scale(1.3);
  position: absolute;
  opacity: 1;
  z-index: 111;
}

.aiLogo {
  background-color: white;
  padding: 2px;
  border-radius: 10;
  border-radius: 25%;
  margin-top: -10px;
  margin-left: -15px;
  width: 20px;
}

.ai-indicator:before {
  content: attr(data-label);
  color: white;
  background-color: black;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 15%;
  position: absolute;
  left: -2.7em;
  font-family: "Helvetica", sans-serif;
  animation-name: pulsetext;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  top: -0.2em;
  font-size: 0.8em;
  opacity: 1;
  transition: opacity 0.5s 0.2s;
}

/* Record Indicator (dot in top-right corner visible when recording)
--------------------------------------------------------------------------------
*/

.record-indicator.hidden {
  display: block !important;
  opacity: 0;
  transform: translateX(1em) rotateX(-90deg) scale(0.9);
  transition: all 0.5s, transform 0.5s 0.25s;
}

.record-indicator {
  width: 0.7em;
  height: 0.7em;
  background-color: #ff625e;
  line-height: 1.1em;
  border-radius: 50%;
  right: 2.75ex;
  top: 2.75ex;
  position: absolute;
  opacity: 1;
  transform: rotateX(0) scale(1.3);
  transition: all 0.5s, transform 0.2s 0.1s;
  z-index: 111;
}

.record-indicator:before {
  content: attr(data-label);
  color: white;
  background-color: black;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 15%;
  position: absolute;
  left: -2.7em;
  font-family: "Helvetica", sans-serif;
  animation-name: pulsetext;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  top: -0.2em;
  font-size: 0.8em;
  opacity: 1;
  transition: opacity 0.5s 0.2s;
}

.record-indicator:after {
  content: "";
  display: block;
  height: calc(0.8em - 4px);
  width: calc(0.8em - 4px);
  border: 2px solid #ff625e;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  transform-origin: center;
  animation-name: pulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 0.25s;
}

@keyframes pulsetext {
  from {
    opacity: 0.75;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.75;
  }
  75% {
    opacity: 0.5;
  }
  to {
    opacity: 0.75;
  }
}

@keyframes pulse {
  from {
    opacity: 1;
    transform: scale(1.6);
  }
  50% {
    opacity: 0.5;
    transform: scale(2.5);
  }
  to {
    opacity: 0;
    transform: scale(3.5);
  }
}

.modal-content {
  background-color: #e2f7f1 !important;
}
.modal-title {
  background-color: transparent !important;
  display: inline-block !important;
}

.modal-header {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: bold !important;
  -webkit-font-smoothing: antialiased;
  font-size: 20px;
  font-weight: 600;
  color: rgb(50, 49, 48);
  box-sizing: border-box;
  box-shadow: none;
  margin: 0px;
  display: block;
  padding: 16px 16px 0px 16px !important;
  overflow-wrap: break-word;
  border: 0 !important;
}

.card {
  border: 0px !important;
  border-top: 1px solid #7c7e80 !important;
}
.card-body {
  background-color: #e2f7f1 !important;
}

.card-horizontal {
  display: flex;
  flex: 1 1 auto;
}

.azure-mp-container {
  background: #000;
  width: 100%;
  display: block;
  padding: 0;
  position: relative;
  overflow: hidden;
  position: relative;
}

.btn {
  border-radius: 16px !important;
  color: black !important;
  background-color: #99efd9 !important;
  border-width: 4px !important;
  border-color: #99efd9 !important;
  font-weight: 600 !important;
  min-width: 100px !important;
}

.btn:hover,
.btn:active,
.btn:visited,
.btn-primary:focus {
  border-color: #55e3b6 !important;
}

.btn.active {
  border-color: #55e3b6 !important;
  background-color: #00d99d !important;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
  border-color: #55e3b6 !important;
}

.btn-primary {
  background-color: #00d99d !important;
  border-color: #00d99d !important;
}

.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:visited,
.btn-secondary:focus {
  border-color: #55e3b6 !important;
}

a {
  color: #026b63 !important;
}

.bg-info {
  background-color: #00be8f !important;
}

.gridwrapper {
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
  /* padding-bottom: 10px; */
}
.one {
  justify-self: start;
  align-self: start;
}
.two {
  justify-self: start;
  align-self: start;
}
.three {
  align-self: end;
  justify-self: start;
}

@media screen and (max-width: 35em) {
  .navLink {
    font-size: 9px;
  }
}

.tableHeight {
  height: 100% !important;
}
.tableHeight.active {
  background-color: #99efd9;
}

.status-sent {
  width: 0.5em;
  height: 0.5em;
  background-color: #00d89d;
  margin-top: 0.5em;
  margin-right: 0.3em;
  border-radius: 50%;
  z-index: 111;
}

@keyframes pulseStatus {
  from {
    opacity: 1;
    transform: scale(1.1);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.4);
  }
  to {
    opacity: 0;
    transform: scale(1.7);
  }
}

.status-other {
  width: 0.5em;
  height: 0.5em;
  background-color: #ff8200;
  margin-top: 0.5em;
  margin-right: 0.3em;
  border-radius: 50%;
  z-index: 111;
  animation-name: pulseStatus;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 0.25s;
}

.actionBar {
  margin: -3px !important;
}

.suggestions {
  position: absolute;
  z-index: 10;
  width: 100%;
  max-height: 215px;
  border: 1px solid rgb(223, 223, 223);
  border-top: none;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: 0 2px 5px -1px rgb(232, 232, 232);
  background-color: white;
  text-align: left;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
}

.suggestion-item {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  padding: 2px 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: default;
  cursor: pointer;
}

.suggestion-active,
.suggestion-item:hover {
  background-color: #00d99d;
  color: white;
}

.radioGroupHighlight {
  border: #ccc;
  border-style: solid;
  border-radius: 25px;
  padding-top: 5px;
}

.mls-invalid {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.mls-select-invalid {
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.media-btn {
  background: transparent;
  border-color: transparent;
  width: 1.7rem;
  height: 1.7rem;
  background: #99efd9;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--clr-red-special);
  cursor: pointer;
  margin-left: 0.1rem;
  align-self: center;
  min-width: 1.7rem;
}

.media-btn:disabled {
  background: #bdf3e5;
  color: #8f9fa4;
  pointer-events: none;
}

.media-btn:hover {
  -webkit-box-shadow: 0px 0px 100px 0px rgba(0, 216, 156, 1);
  -moz-box-shadow: 0px 0px 100px 0px rgba(0, 216, 156, 1);
  box-shadow: 0px 0px 10px 0px rgba(0, 216, 156, 1);
}

.public-ff-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 1/3;
  grid-row-start: 2;
  grid-row-end: 3;
  background-color: #000000;
  height: 100vh;
}

.public-ff-header {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 1/3;
  grid-row-start: 3;
  grid-row-end: 3;
  background-color: #000000;
}

.public-ff {
  width: 92vw;
  max-height: 75vh;
  height: 98vh;
  margin-top: -40px;
}

@media screen and (max-height: 1000px) {
  .public-ff {
    margin-top: 50px;
  }
}

.public-ff-embed {
  width: 92vw;
  max-height: 75vh;
  height: 98vh;
}
.cues {
  color: blue;
}

.cues:hover {
  text-decoration: underline;
  cursor: pointer;
}

.cues.current {
  color: black;
  font-weight: bold;
}

.aiNote {
  font-style: italic;
  font-size: 16px;
}

.summaryExcerpt {
  display: flex;
  align-items: center;
  width: 500px;
}

.summaryTooltip {
  white-space: "nowrap";
  text-overflow: "ellipsis";
  overflow: "hidden";
  min-width: 600px;
  max-width: 600px;
  margin-left: 5px;
}

.column-toggles-panel {
  float: right;
  width: 25%;
  min-width: 200px;
  padding: 5px 2px 2px 5px;
}

.column-toggles-panel header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.column-toggles-panel header div input {
  width: 100% !important;
  outline: none !important;
  border: 0 none !important;
  display: inline-flex !important;
  line-height: 1;
  vertical-align: baseline !important;
  padding: 0.4em 0.4em !important;
  color: black;
  background-color: white;
  font-size: 1rem;
}

.highlight-keywords {
  color: red;
}

/* Apply the custom styles only to devices with a maximum width of 767px, which includes most iPhone models */
@media (max-width: 767px) {
  .video-player {
    max-height: 50vh;
    overflow: auto;
  }
}
