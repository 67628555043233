.hiddenCheckbox {
  display: none;
}

.hiddenCheckbox:focus {
  box-shadow: 0 0 0 3px darkgray;
}

.styledCheckbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  transition: all 150ms;
  margin-bottom: 0px !important;
}

.styledCheckbox.overlay {
  position: absolute;
  top: 5px;
  left: 5px;
}

.styledCheckbox.checked {
  background: #00d89d;
}
.styledCheckbox.unchecked {
  background: #c2d3ce;
}
